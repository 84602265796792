import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Actions from '../actions/listings'
import { localeLinkTo } from '../utils'
import FilterButton from './listings/filter_button'
import Target from './listings/target'
import Locations from './listings/locations'
import Filter from './listings/filter'
import Sorts from './listings/sorts'
import { doPush } from './listings/push'

const L = window.locales

class Top extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      inputValue: '',
      targetOpen: false,
      locationsOpen: false,
      filterOpen: false,
      sortsOpen: false
    }
    let searchParams = new URLSearchParams(this.props.location.search)
    this.props.dispatch(Actions.search(searchParams, false))
  }

  handleLocationsClick() {
    this.setState({
      targetOpen: false,
      locationsOpen: !this.state.locationsOpen,
      filterOpen: false,
      sortsOpen: false
    })
  }

  handleFilterClick() {
    this.setState({
      targetOpen: false,
      locationsOpen: false,
      filterOpen: !this.state.filterOpen,
      sortsOpen: false
    })
  }

  handleSortsClick() {
    this.setState({
      targetOpen: false,
      locationsOpen: false,
      filterOpen: false,
      sortsOpen: !this.state.sortsOpen
    })
  }

  push(params) {
    doPush(this.props, params)
  }

  locationsSet() {
    const { area, title } = this.props.locations
    if (area.name && area.name.length > 0) {
      return true
    } else if (title) {
      return true
    }
    return false
  }

  filterSet() {
    const target = this.props.target
    const { rent, price, bedroom, size, year } = this.props.ranges
    let additionalCount = 0
    this.props.kinds.forEach((kind) => {
      additionalCount++
    })
    Object.keys(window.additionalsMap).forEach((key) => {
      if (this.props.additionals[key] == 'on') {
        additionalCount++
      }
    })
    if (additionalCount != 0) {
      return true
    }

    if (window.currency == 'JPY') {
      if (target == 'rent' || target == undefined || target == 'monthly') {
        if (rent[0] != window.search_rent_min || rent[1] != window.search_rent_max) {
          return true
        }
      } else if (target == 'buy') {
        if (price[0] != window.search_price_min || price[1] != window.search_price_max) {
          return true
        }
      }
    } else {
      if (target == 'rent' || target == undefined || target == 'monthly') {
        if (rent[0] != window.search_rent_min || rent[1] != window.search_rent_max) {
          return true
        }
      } else if (target == 'buy') {
        if (price[0] != window.search_price_min || price[1] != window.search_price_max) {
          return true
        }
      }
    }
    if (bedroom[0] != 0 || bedroom[1] != 5) {
      return true
    }
    if ((size[0] != window.search_area_min && size[0] != window.search_area_min * 10.5) || (size[1] != window.search_area_max && size[1] != window.search_area_max * 10.5)) {
      return true
    }
    if (year[0] != window.search_year_min || year[1] != window.search_year_max) {
      return true
    }

    return false
  }

  sortSet() {
    switch (this.props.sorts.sortOrder) {
    case 'rent-asc':
    case 'rent-desc':
    case 'price-asc':
    case 'price-desc':
    case 'area-asc':
    case 'area-desc':
    case 'rent_per-asc':
    case 'rent_per-desc':
    case 'year_built-asc':
    case 'year_built-desc':
      return true
    default:
      return false
    }
  }

  handleTargetChange(target) {
    this.props.dispatch(Actions.changeTarget(target))
    this.props.dispatch(Actions.changeSortUnit(null, this.props.sorts.units))
    this.props.dispatch(Actions.changeKinds([]))
    let additionals = {}
    Object.keys(window.additionalsMap).forEach((key) => {
      additionals[key] = 'off'
      this.props.dispatch(Actions.changeAdditional({[key]: 'off'}))
    })
    this.push(Object.assign({}, {area: {clear: true}}, {rent: 'clear'}, {price: 'clear'}, {bedroom: 'clear'}, {target: target}, {kinds: []}, additionals, {page: 'clear'}, {sortOrder: 'clear'}, {unit: 'clear'}, {areaFor: 'clear'}))
  }

  render() {
    const { dispatch, loading, page, buyPage, monthlyPage, target, locations, ranges, kinds, additionals, sorts } = this.props

    let status = <><i className="fas fa-sync-alt fa-spin fa-fw"></i>{L.loading}</>
    if (target == 'rent' && page.units_count) {
        status = <>{L.n_long_term_rentals.replace('%{n}', page.units_count ? page.units_count : '-')}</>
    } else if(target == 'buy' && buyPage.units_count) {
        status = <>{L.n_properties_for_sale.replace('%{n}', buyPage.units_count ? buyPage.units_count : '-')}</>
    } else if(target == 'monthly' && monthlyPage.units_count) {
        status = <>{L.n_furnished_monthly_rentals.replace('%{n}', monthlyPage.units_count ? monthlyPage.units_count : '-')}</>
    }

    return (
      <>
        <div className="f-search-buttons f-row">
          <button className={`select-search${target == 'rent' ? ' select-search-selected' : ''}`} onClick={this.handleTargetChange.bind(this, 'rent')}>{L.long_term}</button>
          <button className={`select-search${target == 'buy' ? ' select-search-selected' : ''}`} onClick={this.handleTargetChange.bind(this, 'buy')}>{L.purchase}</button>
          <button className={`select-search${target == 'monthly' ? ' select-search-selected' : ''}`} onClick={this.handleTargetChange.bind(this, 'monthly')}>{L.monthly}</button>
        </div>
        <div className="f-search f-column f-center">
          <div className="f-search-status">
            {status}
          </div>
          <div className="f-search-filters f-row">
            <button className={`f-filter-button${this.locationsSet() ? ' f-search-filter-set' : ''}`} onClick={this.handleLocationsClick.bind(this)}><i className="fas fa-map-marker-alt"></i>&nbsp;{L.location}</button>
            <span>|</span>
            <button className={`f-filter-button${this.filterSet() ? ' f-search-filter-set' : ''}`} onClick={this.handleFilterClick.bind(this)}><i className="fas fa-sliders-h"></i>&nbsp;{L.filter}</button>
            <span>|</span>
            <button className={`f-filter-button${this.sortSet() ? ' f-search-filter-set' : ''}`} onClick={this.handleSortsClick.bind(this)}><i className="fas fa-sort-amount-down"></i>&nbsp;{L.sort}</button>

            <button className="f-search-main-button" onClick={() => window.location.href = localeLinkTo(`/search${window.location.search}`)}><i className="fas fa-search"></i></button>

            <Locations dispatch={dispatch} target={target} locations={locations} open={this.state.locationsOpen} push={this.push.bind(this)} onCloseClick={this.handleLocationsClick.bind(this)} page={1} />
            <Filter dispatch={dispatch} target={target} ranges={ranges} sorts={sorts} kinds={kinds} additionals={additionals} open={this.state.filterOpen} push={this.push.bind(this)} onCloseClick={this.handleFilterClick.bind(this)} page={1} />
            <Sorts dispatch={dispatch} target={target} sorts={sorts} open={this.state.sortsOpen} push={this.push.bind(this)} onCloseClick={this.handleSortsClick.bind(this)} page={1} />
          </div>
        </div>
      </>
    )
  }
}

Top.propTypes = {
  loading: PropTypes.bool.isRequired,
  page: PropTypes.object.isRequired,
  buyPage: PropTypes.object.isRequired,
  monthlyPage: PropTypes.object.isRequired,
  target: PropTypes.string,
  locations: PropTypes.object.isRequired,
  ranges: PropTypes.object.isRequired,
  kinds: PropTypes.array,
  additionals: PropTypes.object.isRequired,
  sorts: PropTypes.object.isRequired
}

export default withRouter(Top)
